import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import collegeImage from 'WEBSITE/Components/LoginPage/images/vm-dummy-banner.png';
import { Base64 } from 'js-base64';
import ReactHtmlParser from 'react-html-parser';
import 'WEBSITE/Components/LoginPage/loginpage.css';
import axios from 'axios';
import Spinner from 'WEBSITE/CommonResources/SpinnerLoader/Spinner';

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            instituteUrl: this.props.match.params.instituteUrl,
            instituteName: '',
            instituteLogo: '',
            instituteImage: '',
            instituteId: '',
            instituteDetailsFetching: false,
            userName: '',
            userNameWarning: '',
            password: '',
            passwordWarning: '',
            responseMessage: '',
            loggedIn: false,
            accountType: ''
        };
    }

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();

        // METHOD CALLED TO GET INSTITUTE DETAILS
        this.getInstituteDetails();

        var cookies = decodeURIComponent(document.cookie);
        let userInfo = cookies.split(';');
        var cookieData = new Map();
        for(let i = 0; i < userInfo.length; i++) {
            let key = userInfo[i].split("=")[0].trim();
            let value = userInfo[i].split("=")[1];
            cookieData.set(key, value);
        }
        if(cookieData.has('user_details') && cookieData.has('user_token') && cookieData.has('sync_token')){
            this.setState({
                loggedIn: true
            });
            let userDetails = cookieData.get('user_details');

            let decodedUserDetails = JSON.parse(Base64.decode(Base64.decode(userDetails)));

            this.setState({
                accountType: decodedUserDetails.data.account
            });
        }else {
            this.setState({
                loggedIn: false
            });
        }
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // METHOD TO VIEW PASSWORD
    viewPassword = () => {
        let passwordText = document.getElementById('passwordValue');
        passwordText.type = 'text';
    }

    // METHOD TO HIDE PASSWORD
    hidePassword = () => {
        let passwordText = document.getElementById('passwordValue');
        passwordText.type = 'password';
    }

    // METHOD TO GET INSTITUTE DETAILS FROM INSTITUTE URL
    getInstituteDetails = async () => {
        this.setState({
            instituteDetailsFetching: true
        });
        let formData = new FormData();
        let urlData = {
            "url": this.state.instituteUrl
        };
        formData.append("getInstituteDataByURL", true);
        formData.append("data", Base64.encode(JSON.stringify(urlData)));

        axios({
            method: "post",
            url: (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin)  + "/api/generic_api.php",
            data: formData,
        }).then((response) => {
            let jsonResponse = response.data;
            this.setState({
                instituteName: jsonResponse.data.InstituteName,
                instituteLogo: jsonResponse.data.InstituteLogo,
                instituteImage: jsonResponse.data.InstituteBannerImage,
                instituteId: jsonResponse.data.InstituteId,
                instituteDetailsFetching: false
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                instituteDetailsFetching: false
            });
        });
    }

    // METHOD TO GET USER NAME
    getUserName = (e) => {
        this.setState({
            userName: e.target.value
        });

        if(e.target.value.length < 1) {
            this.setState({
                userNameWarning: 'Username cannot be empty.'
            });
        }else {
            this.setState({
                userNameWarning: ''
            });
        }
    }

    // METHOD TO GET PASSWORD
    getPassword = (e) => {
        this.setState({
            password: e.target.value
        });

        if(e.target.value.length < 1) {
            this.setState({
                passwordWarning: 'Password cannot be empty.'
            });
        }else {
            this.setState({
                passwordWarning: ''
            });
        }
    }

    // METHOD TO VALIDATE LOGIN DATA
    validateLoginData = () => {
        let validData = true;

        if(this.state.userName.length < 1) {
            this.setState({
                userNameWarning: 'Username cannot be empty.'
            });
            validData = false;
        }

        if(this.state.password.length < 1) {
            this.setState({
                passwordWarning: 'Password cannot be empty.'
            });
            validData = false;
        }

        if(validData) {
            this.apiCallToLoginToPortal();
        }
    }

    // API CALL METHOD TO LOGIN TO PORTAL
    apiCallToLoginToPortal = async () => {
        let formData = new FormData();
        let userData = {
            "username": this.state.userName,
            "password": this.state.password,
            'institute_id': this.state.instituteId
        };

        formData.append("verifyInstituteUserCredentials", true);
        formData.append("data", Base64.encode(JSON.stringify(userData)));

        axios({
            method: "post",
            url: (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin)  + "/api/generic_api.php",
            data: formData,
        }).then((response) => {
            let jsonResponse = response.data;
            if(jsonResponse.success == 'true') {
                function setCookie(name,value) {
                    document.cookie = name + "=" + (value || "");
                }
                if(jsonResponse.account == 'faculty') {
                    let userDetails = {};
                    if(userDetails.data == undefined) {
                        userDetails.data = {};
                    }

                    userDetails.data.account = jsonResponse.account;
                    userDetails.data.user_id = jsonResponse.user;
                    userDetails.data.AcaYear = jsonResponse.facultyData.AcaYear;
                    userDetails.data.AcaYearId = jsonResponse.facultyData.AcaYearId;
                    userDetails.data.DOB = jsonResponse.facultyData.DOB;
                    userDetails.data.EmailId = jsonResponse.facultyData.EmailId;
                    userDetails.data.FirstName = jsonResponse.facultyData.FirstName;
                    userDetails.data.InstitudeId = jsonResponse.facultyData.InstitudeId;
                    userDetails.data.InstituteLogo = jsonResponse.facultyData.InstituteLogo;
                    userDetails.data.InstituteName = jsonResponse.facultyData.InstituteName;
                    userDetails.data.InstituteShortName = jsonResponse.facultyData.InstituteShortName;
                    userDetails.data.InstituteUrl = jsonResponse.facultyData.InstituteUrl;
                    userDetails.data.Institute_iid = jsonResponse.facultyData.Institute_iid;
                    userDetails.data.LastName = jsonResponse.facultyData.LastName;
                    userDetails.data.MobileNumber = jsonResponse.facultyData.MobileNumber;
                    userDetails.data.StreamId = jsonResponse.facultyData.StreamId;
                    userDetails.data.StreamName = jsonResponse.facultyData.StreamName;
                    userDetails.data.UserDp = jsonResponse.facultyData.UserDp;
                    userDetails.data.Points = jsonResponse.facultyData.Points;
                    userDetails.data.uid = jsonResponse.facultyData.uid;
                    userDetails.role = jsonResponse.facultyData.facultyRole;
                    userDetails.success = 'true';

                    let encodedUserDetails = Base64.encode(Base64.encode(JSON.stringify(userDetails)));
                    let encodedAcademicYear = Base64.encode(Base64.encode(JSON.stringify(jsonResponse.AcademicYearList)));
                    let encodedStreamList = Base64.encode(Base64.encode(JSON.stringify(jsonResponse.facultyData.Stream)));
                    setCookie('acyear', encodedAcademicYear);
                    setCookie('user_token', jsonResponse.tokenDetails.data.user_token);
                    setCookie('sync_token', jsonResponse.tokenDetails.data.sync_token);
                    setCookie('userStreamList', encodedStreamList);
                    setCookie('user_details', encodedUserDetails);

                    if(jsonResponse.facultyData.MobileNumber != '' && jsonResponse.facultyData.DOB != '' && jsonResponse.facultyData.EmailId != '') {
                        window.location.href = (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin) + '/faculty/FacultySummary.php';
                    }else {
                        window.location.href = (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin) + '/userMobileEmailValidation.php';
                    }
                }else {
                    let userDetails = {};
                    if(userDetails.data == undefined) {
                        userDetails.data = {};
                    }

                    userDetails.data.account = jsonResponse.account;
                    userDetails.data.user_id = jsonResponse.user;
                    userDetails.data.Acayear = jsonResponse.studentData.Acayear;
                    userDetails.data.DOB = jsonResponse.studentData.DOB;
                    userDetails.data.EmailId = jsonResponse.studentData.EmailId;
                    userDetails.data.FirstName = jsonResponse.studentData.FirstName;
                    userDetails.data.InstitudeId = jsonResponse.studentData.InstitudeId;
                    userDetails.data.InstituteLogo = jsonResponse.studentData.InstituteLogo;
                    userDetails.data.InstituteName = jsonResponse.studentData.InstituteName;
                    userDetails.data.InstituteShortName = jsonResponse.studentData.InstituteShortName;
                    userDetails.data.InstituteUrl = jsonResponse.studentData.InstituteUrl;
                    userDetails.data.LastName = jsonResponse.studentData.LastName;
                    userDetails.data.MobileNumber = jsonResponse.studentData.MobileNumber;
                    userDetails.data.PRNno = jsonResponse.studentData.PRNno;
                    userDetails.data.PlStream = jsonResponse.studentData.PlStream;
                    userDetails.data.Points = jsonResponse.studentData.Points;
                    userDetails.data.UserDp = jsonResponse.studentData.UserDp;
                    userDetails.data.stuid = jsonResponse.studentData.stuid;
                    userDetails.role = 'learner';
                    userDetails.success = 'true';

                    let encodedUserDetails = Base64.encode(Base64.encode(JSON.stringify(userDetails)));
                    let encodedAcademicYear = Base64.encode(Base64.encode(JSON.stringify(jsonResponse.AcademicYearList)));
                    let encodedElectiveBatches = Base64.encode(JSON.stringify(jsonResponse.studentData.electiveBatches.data));
                    let encodedGroupDetails = Base64.encode(JSON.stringify(jsonResponse.studentData.groupDetailsArray));
                    let encodedAssignedBatches = Base64.encode(JSON.stringify(jsonResponse.studentData.studentAssignedBatches.data));
                    setCookie('acyear', encodedAcademicYear);
                    setCookie('user_token', jsonResponse.tokenDetails.data.user_token);
                    setCookie('sync_token', jsonResponse.tokenDetails.data.sync_token);
                    setCookie('user_details', encodedUserDetails);
                    setCookie('user_details', encodedUserDetails);
                    setCookie('user_details', encodedUserDetails);
                    setCookie('learner_studentAssignedBatches', encodedAssignedBatches);
                    setCookie('learner_groupDetailsArray', encodedGroupDetails);
                    setCookie('learner_studentAssignedElectiveBatches', encodedElectiveBatches);

                    if(jsonResponse.studentData.MobileNumber != '' && jsonResponse.studentData.DOB != '' && jsonResponse.studentData.EmailId != '') {
                        window.location.href = (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin) + '/student/Summary.php';
                    }else {
                        window.location.href = (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin) + '/userMobileEmailValidation.php';
                    }
                }
            }
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        console.log(this.state.accountType);
        let instituteImage = '';
        let instituteLogo = '';
        if(this.state.instituteImage != undefined) {
            let instituteImageExists = (/\.(gif|jpg|jpeg|tiff|png)$/i).test(this.state.instituteImage);
            if(!instituteImageExists) {
                instituteImage = this.state.instituteImage;
            }else {
                instituteImage = collegeImage;
            }
        }
        if(this.state.instituteLogo != undefined) {
            let instituteLogoExists = (/\.(gif|jpg|jpeg|tiff|png|GIF|JPG|JPEG|TIFF|PNG)$/i).test(this.state.instituteLogo);
            if(instituteLogoExists) {
                instituteLogo = this.state.instituteLogo;
            }else {
                instituteLogo = collegeImage;
            }
        }
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                {
                    this.state.instituteDetailsFetching ? 
                        <Spinner />
                    :
                    <div className="institute-container">
                        <div className="institute-name">
                            <div className="instituteLogo-image">
                                <img src={instituteLogo} className="instituteLogo-img" />
                            </div>
                            <div className="instituteName">
                                <h2>{ReactHtmlParser(this.state.instituteName)}</h2>
                            </div>
                        </div>
                        <div className="login-container" style={{ backgroundImage: `url(${instituteImage})` }}>
                            {
                                this.state.loggedIn ?
                                    <div className="login-form col-md-4">
                                        <div className="access-account-button">
                                            <button className="btn access-account" onClick={() => window.location.href = this.state.accountType == 'faculty' ? (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin) + '/faculty/FacultySummary.php' : (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin) + '/student/Summary.php'}>Access Account</button>
                                        </div>
                                    </div>
                                :
                                <div className="login-form col-md-4">
                                    <div style={{marginBottom: 50 }}>
                                        <div style={{ display: 'flex', marginBottom: 10 }}>
                                            <span style={{ color: 'red' }}>*</span>
                                            <input
                                                type="text"
                                                placeholder="Username or Email Address"
                                                className="form-control"
                                                onChange={(e) => this.getUserName(e)}
                                            />
                                        </div>
                                        <p style={{ display: this.state.userNameWarning.length > 0 ? 'block' : 'none', color: '#FFFFFF', backgroundColor: 'crimson', width: 'fit-content', padding: 5 }}>{this.state.userNameWarning}</p>
                                    </div>
                                    <div style={{ marginBottom: 50 }}>
                                        <div style={{ display: 'flex', marginBottom: 10 }}>
                                            <span style={{ color: 'red' }}>*</span>
                                            <input
                                                id="passwordValue"
                                                type="password"
                                                placeholder="Password"
                                                className="form-control"
                                                onChange={(e) => this.getPassword(e)}
                                            />
                                            <FontAwesomeIcon icon={['fas','eye']} style={{ cursor: 'pointer' }} onMouseOver={() => this.viewPassword()}  onMouseLeave={() => this.hidePassword()}/>
                                        </div>
                                        <p style={{ display: this.state.passwordWarning.length > 0 ? 'block' : 'none', color: '#FFFFFF', backgroundColor: 'crimson', width: 'fit-content', padding: 5 }}>{this.state.passwordWarning}</p>
                                    </div>
                                    <div style={{ textAlign: 'center' }}>
                                        <button className="btn submit-login" onClick={() => this.validateLoginData()}>LOG IN</button>
                                    </div>
                                    <div className="forgot-password">
                                        <Link to={`/forgot-password/${this.state.instituteUrl}`} style={{ color: '#212529' }}><p className="forgot-password-text">Forgot Password ?</p></Link>
                                    </div>
                                    {
                                        this.state.responseMessage.length > 0 &&
                                            <div className="responseMessage">
                                                <p className="alert alert-danger responseText">
                                                    {this.state.responseMessage}
                                                    <FontAwesomeIcon icon={['fas','times']} style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => this.setState({ responseMessage: '' })}/>
                                                </p>
                                            </div>
                                    }
                                </div>
                            }
                            {/* <div className="login-form col-md-4">
                                <div style={{marginBottom: 50 }}>
                                    <div style={{ display: 'flex', marginBottom: 10 }}>
                                        <span style={{ color: 'red' }}>*</span>
                                        <input
                                            type="text"
                                            placeholder="Username or Email Address"
                                            className="form-control"
                                            onChange={(e) => this.getUserName(e)}
                                        />
                                    </div>
                                    <p style={{ display: this.state.userNameWarning.length > 0 ? 'block' : 'none', color: '#FFFFFF', backgroundColor: 'crimson', width: 'fit-content', padding: 5 }}>{this.state.userNameWarning}</p>
                                </div>
                                <div style={{ marginBottom: 50 }}>
                                    <div style={{ display: 'flex', marginBottom: 10 }}>
                                        <span style={{ color: 'red' }}>*</span>
                                        <input
                                            id="passwordValue"
                                            type="password"
                                            placeholder="Password"
                                            className="form-control"
                                            onChange={(e) => this.getPassword(e)}
                                        />
                                        <FontAwesomeIcon icon={['fas','eye']} style={{ cursor: 'pointer' }} onMouseOver={() => this.viewPassword()}  onMouseLeave={() => this.hidePassword()}/>
                                    </div>
                                    <p style={{ display: this.state.passwordWarning.length > 0 ? 'block' : 'none', color: '#FFFFFF', backgroundColor: 'crimson', width: 'fit-content', padding: 5 }}>{this.state.passwordWarning}</p>
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <button className="btn submit-login" onClick={() => this.validateLoginData()}>LOG IN</button>
                                </div>
                                <div className="forgot-password">
                                    <Link to={`/forgot-password/${this.state.instituteUrl}`} style={{ color: '#212529' }}><p className="forgot-password-text">Forgot Password ?</p></Link>
                                </div>
                                {
                                    this.state.responseMessage.length > 0 &&
                                        <div className="responseMessage">
                                            <p className="alert alert-danger responseText">
                                                {this.state.responseMessage}
                                                <FontAwesomeIcon icon={['fas','times']} style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => this.setState({ responseMessage: '' })}/>
                                            </p>
                                        </div>
                                }
                            </div> */}
                        </div>
                    </div>
                }
                
            </>
        );
    }
}

export default LoginPage;