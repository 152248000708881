import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import 'mdbreact/dist/css/mdb.css';

import WebsiteHeader from 'WEBSITE/WebsiteHeader/WebsiteHeader';
import WebsiteRoutes from 'WEBSITE/WebsiteRoutes/WebsiteRoutes';
import WebsiteFooter from 'WEBSITE/WebsiteFooter/WebsiteFooter';
// NEW LOGIC TO SHOW THE START UP SCREEN

library.add(fab, far, fas);

ReactDOM.render(
  <HashRouter>
    <WebsiteHeader />
      <WebsiteRoutes />
    <WebsiteFooter />
  </HashRouter>,
  document.getElementById('root')
);
