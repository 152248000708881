import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import presence from 'WEBSITE/Components/OurPresence/images/presence.png';
import 'WEBSITE/Components/OurPresence/ourpresence.css';
import maharashtra from 'WEBSITE/Components/WebsiteInfo/images/maharashtra.png';
import delhi from 'WEBSITE/Components/WebsiteInfo/images/delhi.png';
import uttarpradesh from 'WEBSITE/Components/WebsiteInfo/images/uttarpradesh.png';
import tamilnadu from 'WEBSITE/Components/WebsiteInfo/images/tamilnadu.png';
import andhra from 'WEBSITE/Components/WebsiteInfo/images/andhra.png';
import karnataka from 'WEBSITE/Components/WebsiteInfo/images/karnataka.png';
import himachal from 'WEBSITE/Components/WebsiteInfo/images/himachal.png';
import mizoram from 'WEBSITE/Components/WebsiteInfo/images/mizoram.png';
import hyderabad from 'WEBSITE/Components/WebsiteInfo/images/hyderabad.png';
import kerala from 'WEBSITE/Components/WebsiteInfo/images/kerala.png';
import gujrat from 'WEBSITE/Components/WebsiteInfo/images/gujrat.png';

class OurPresence extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="presence-header">
                <h2>Our Presence</h2>
                </div>
                <div className="presence-info">
                    <h1>“Implementing Across Nation”</h1>
                </div>
                <div className="our-presence col-md-12">
                <div className="presence-container">
                    <div className="presence-card">
                        <img src={`${maharashtra}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>MAHARASHTRA</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${delhi}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>DELHI</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${uttarpradesh}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>UTTAR PRADESH</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${tamilnadu}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>TAMIL NADU</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${andhra}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>ANDHRA PRADESH</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${karnataka}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>KARNATAKA</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${himachal}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>HIMACHAL PRADESH</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${mizoram}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>MIZORAM</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${hyderabad}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>TELANGANA</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${kerala}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>KERALA</h6>
                    </div>
                    <div className="presence-card">
                        <img src={`${gujrat}`} className="presence-img" />
                        <h6 style={{ fontWeight: 'bold' }}>GUJRAT</h6>
                    </div>
                </div>
            </div>
            {/**               
                 <div className="presenceList-container" style={{ backgroundImage: `url(${presence})` }}>
                    <div className="presenceList">
                        <div className="presence">
                            <h6>MAHARASHTRA</h6>
                        </div>
                        <div className="presence">
                            <h6>GUJRAT</h6>
                        </div>
                        <div className="presence">
                            <h6>HIMACHAL PRADESH</h6>
                        </div>
                        <div className="presence">
                            <h6>UTTAR PRADESH</h6>
                        </div>
                        <div className="presence">
                            <h6>ANDHRA PRADESH</h6>
                        </div>
                        <div className="presence">
                            <h6>KARNATAKA</h6>
                        </div>
                        <div className="presence">
                            <h6>DELHI</h6>
                        </div>
                        <div className="presence">
                            <h6>MIZORAM</h6>
                        </div>
                        <div className="presence">
                            <h6>TELANGANA</h6>
                        </div>
                        <div className="presence">
                            <h6>TAMIL NADU</h6>
                        </div>
                        <div className="presence">
                            <h6>KERALA</h6>
                        </div>
                    </div>
                </div>
                */}

            </>
        );
    }
}

export default OurPresence;