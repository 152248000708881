import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import collegeImage from 'WEBSITE/Components/LoginPage/images/vm-dummy-banner.png';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';
import { Base64 } from 'js-base64';
import 'WEBSITE/Components/ForgotPassword/forgotpassword.css';
import Spinner from 'WEBSITE/CommonResources/SpinnerLoader/Spinner';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            instituteUrl: this.props.match.params.instituteUrl,
            instituteName: '',
            instituteLogo: '',
            instituteImage: '',
            instituteId: '',
            userName: '',
            userNameWarning: '',
            responseMessage: '',
            responseStatus: '',
        };
    }

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();

        // METHOD CALLED TO GET INSTITUTE DETAILS
        this.getInstituteDetails();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // METHOD TO VIEW PASSWORD
    viewPassword = () => {
        let passwordText = document.getElementById('passwordValue');
        passwordText.type = 'text';
    }

    // METHOD TO HIDE PASSWORD
    hidePassword = () => {
        let passwordText = document.getElementById('passwordValue');
        passwordText.type = 'password';
    }

    // METHOD TO GET INSTITUTE DETAILS FROM INSTITUTE URL
    getInstituteDetails = async () => {
        this.setState({
            instituteDetailsFetching: true
        });
        let formData = new FormData();
        let urlData = {
            "url": this.state.instituteUrl
        };
        formData.append("getInstituteDataByURL", true);
        formData.append("data", Base64.encode(JSON.stringify(urlData)));

        axios({
            method: "post",
            url: (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin)  + "/api/generic_api.php",
            data: formData,
        }).then((response) => {
            let jsonResponse = response.data;
            this.setState({
                instituteName: jsonResponse.data.InstituteName,
                instituteLogo: jsonResponse.data.InstituteLogo,
                instituteImage: jsonResponse.data.InstituteBannerImage,
                instituteId: jsonResponse.data.InstituteId,
                instituteDetailsFetching: false
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                instituteDetailsFetching: false
            });
        });
    }

    // METHOD TO GET USER NAME
    getUserName = (e) => {
        this.setState({
            userName: e.target.value
        });

        if(e.target.value.length < 1) {
            this.setState({
                userNameWarning: 'Username cannot be empty.'
            });
        }else {
            this.setState({
                userNameWarning: ''
            });
        }
    }

    // METHOD TO VALIDATE RESET DATA
    validateResetData = () => {
        let validData = true;

        if(this.state.userName.length < 1) {
            this.setState({
                userNameWarning: 'Username cannot be empty.'
            });
            validData = false;
        }

        if(validData) {
            this.apiCallToGetResetLink();
        }
    }

    // METHOD API CALL TO GET PASSWORD LINK
    apiCallToGetResetLink = async () => {
        let formData = new FormData();
        let userData = {
            "user_id": this.state.userName,
            "institute_id": this.state.instituteId
        };

        formData.append("getResetPasswordLink", true);
        formData.append("data", Base64.encode(JSON.stringify(userData)));

        axios({
            method: "post",
            url: (window.location.origin == 'http://localhost:3000' ? 'https://stage.vmedulife.com' : window.location.origin)  + "/api/generic_api.php",
            data: formData,
        }).then((response) => {
            let jsonResponse = response.data;
            if(jsonResponse.status == 'success') {
                this.setState({
                    userName: '',
                    responseStatus: 'success',
                    responseMessage: jsonResponse.message
                });
            }else {
                this.setState({
                    responseStatus: 'fail',
                    responseMessage: jsonResponse.message
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return(
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="institute-container">
                    <div className="passwordReset-container" style={{ backgroundImage: `url(${collegeImage})` }}>
                        <div className="passwordReset-form col-md-4">
                            <div className="passwordResetHeader">
                                <h4>Password Reset Request</h4>
                            </div>
                            <div style={{ marginBottom: 50 }}>
                                <div style={{ display: 'flex', marginBottom: 10 }}>
                                    <span style={{ color: 'red' }}>*</span>
                                    <input
                                        type="text"
                                        placeholder="Username or Email Address"
                                        className="form-control"
                                        value={this.state.userName}
                                        onChange={(e) => this.getUserName(e)}
                                    />
                                </div>
                                <p style={{ display: this.state.userNameWarning.length > 0 ? 'block' : 'none', color: '#FFFFFF', backgroundColor: 'crimson', width: 'fit-content', padding: 5 }}>{this.state.userNameWarning}</p>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <button className="btn submit-passwordReset" onClick={() => this.validateResetData()}>Request</button>
                            </div>
                            <div className="return-login">
                                <Link to={`/login/${this.state.instituteUrl}`} style={{ color: '#212529' }}><p className="return-login-text">Return to Login</p></Link>
                            </div>
                            <div className="query-details">
                                <p>In case of any issue, please e-mail us at <span style={{ color: '#007bff', fontWeight: 'bold' }}>info@vmedulife.com</span></p>
                            </div>
                            {
                                this.state.responseMessage.length > 0 &&
                                    <div className="responseMessage">
                                        <p className={this.state.responseStatus == 'success' ? 'alert alert-success responseText' : 'alert alert-danger responseText'}>
                                            {this.state.responseMessage}
                                            <FontAwesomeIcon icon={['fas','times']} style={{ marginLeft: 10, cursor: 'pointer' }} onClick={() => this.setState({ responseMessage: '', responseStatus: '' })}/>
                                        </p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ForgotPassword;