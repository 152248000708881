import React from 'react';
import 'WEBSITE/CommonResources/SpinnerLoader/spinner.css';

class Spinner extends React.Component {
    render() {
        return (
            <div className="loading-container">
                <div className="loading"></div>
                <div id="loading-text">loading</div>
            </div>
        );
    }
}

export default Spinner;