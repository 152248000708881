import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import outcomeEducation from 'WEBSITE/Components/WebsiteInfo/images/pencil.png';
import graduation from 'WEBSITE/Components/WebsiteInfo/images/graduation.png';
import settings from 'WEBSITE/Components/WebsiteInfo/images/settings.png';
import 'WEBSITE/WebsiteHeader/websiteheader.css';

class WebsiteHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showNavigationMenu: false,
            showProducts: true,
            showSuccess: false,
            showResources: false,
            showAbout: false,
        };
    }

    render() {
        return (
            <>
        
                <div className="infoLink-Header">
                    <div className="infoLink">
                        <FontAwesomeIcon icon={['far', 'envelope']} size="lg" style={{marginRight: 5}} />
                        <b><a className="infoLink-title">info@vmedulife.com</a></b>
                    </div>
                    <div className="contactLink">
                        <FontAwesomeIcon icon={['fas', 'phone-alt']} size="lg" style={{marginRight: 5}} />    
                        <Link to="/contact-us" style={{ color: '#212529' }}><b>Sales Enquiry</b></Link>
                    </div>
                </div>
                <div className="navigation-menu">
                    <div className="navbar-toggle navigationButton">
                        <button className="btn toggle-button" onClick={() => this.setState({ showNavigationMenu: !this.state.showNavigationMenu })}>
                            <FontAwesomeIcon icon={['fas','bars']} />
                        </button>
                    </div>
                    <Link to="/">
                        <div className="company-title"> 
                            <span className="companyName">vmedulife</span>
                            <span className="registered"><sup>&reg;</sup></span>
                        </div>
                    </Link>
                    <div  className="allMenu">
                        <div className="dropdown mega-menu-full">
                            <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Products
                            </span>
                            <div className="dropdown-menu productMenu" aria-labelledby="dropdownMenuButton"> 
                                <div className="mega-menu-content">
                                    <div className="row link-container">
                                        <div className="featuresData col-md-4">
                                            <div className="outcome-education">
                                                <img src={`${outcomeEducation}`} style={{ marginRight: 40 }} />
                                                <Link to="/outcome-education" style={{ color: '#212529' }}><h5 style={{ fontWeight: 'bold', cursor: 'pointer' }}>Outcome Based Education Software (Accreditation)</h5></Link>
                                            </div>
                                            <hr />
                                            <div className="academic-software">
                                                <img src={`${graduation}`} style={{ marginRight: 40 }} />
                                                <Link to="/academic-management" style={{ color: '#212529' }}><h5 style={{ fontWeight: 'bold', cursor: 'pointer' }}>Academic Management Software</h5></Link>
                                            </div>
                                            <hr />
                                            <div className="office-automation">
                                                <img src={`${settings}`} style={{ marginRight: 40 }} />
                                                <Link to="/office-automation" style={{ color: '#212529' }}><h5 style={{ fontWeight: 'bold', cursor: 'pointer' }}>Office Automation (ERP)</h5></Link>
                                            </div>
                                        </div>
                                        <div className="col-md-3 academic-menu">
                                            <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ACADEMICS & EXAMINATION</h6>
                                            <Link to="/academic-planning" style={{ color: '#212529' }}><p>Academic Planning</p></Link>
                                            <Link to="/outcome" style={{ color: '#212529' }}><p>Outcome Software</p></Link>
                                            <Link to="/online-exam" style={{ color: '#212529' }}><p>Online Exam Software</p></Link>
                                            <Link to="/assignment-management" style={{ color: '#212529' }}><p>Online Assignment Software</p></Link>
                                            <Link to="/result-analysis" style={{ color: '#212529' }}><p>Result Analysis</p></Link>
                                            <Link to="/rubrics" style={{ color: '#212529' }}><p>Rubrics</p></Link>
                                            <Link to="/iqac-reports" style={{ color: '#212529' }}><p>IQAC Reports</p></Link>
                                            <Link to="/autonomous-exam-management" style={{ color: '#212529' }}><p>Autonomy Exam Management</p></Link>
                                            <Link to="/learning-management-system" style={{ color: '#212529' }}><p>Learning Management System</p></Link>
                                            <Link to="/course-file" style={{ color: '#212529' }}><p>Course File - Academic</p></Link>
                                            <Link to="/faculty-profile" style={{ color: '#212529' }}><p>Faculty Profile</p></Link>
                                            <Link to="/library-management" style={{ color: '#212529' }}><p>Integrated Library Management System</p></Link>
                                            <Link to="/training-placement" style={{ color: '#212529' }}><p>Training & Placement Management</p></Link>
                                            <Link to="/alumni-management" style={{ color: '#212529' }}><p>Alumni Management</p></Link>
                                        </div>
                                        <div className="col-md-3 admin-menu">
                                            <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ADMINISTRATIVE</h6>
                                            <Link to="/employee-management" style={{ color: '#212529' }}><p>Employee Management</p></Link>
                                            <Link to="/admission-management" style={{ color: '#212529' }}><p>Admission</p></Link>
                                            <Link to="/online-feedback" style={{ color: '#212529' }}><p>Online Feedback</p></Link>
                                            <Link to="/committee-meetings-management" style={{ color: '#212529' }}><p>Committee & Meeting Management</p></Link>
                                            <Link to="/online-grievance" style={{ color: '#212529' }}><p>Online Grievance Redressal Portal</p></Link>
                                            <Link to="/forms" style={{ color: '#212529' }}><p>Forms</p></Link>
                                            <Link to="/leave-management" style={{ color: '#212529' }}><p>Attendance & Leave Management System</p></Link>
                                            <Link to="/event-management" style={{ color: '#212529' }}><p>Events</p></Link>
                                            <Link to="/noticeboard" style={{ color: '#212529' }}><p>Notice Board</p></Link>
                                            <Link to="/transport" style={{ color: '#212529' }}><p>Transport Management System </p></Link>
                                            <Link to="/sms-panel" style={{ color: '#212529' }}><p>SMS Panel </p></Link>
                                            <Link to="/inward-outward" style={{ color: '#212529' }}><p>Inward / Outward </p></Link>
                                            <Link to="/hostel-management" style={{ color: '#212529' }}><p>Hostel Management </p></Link>
                                            <Link to="/inventory-management" style={{ color: '#212529' }}><p>Inventory Management</p></Link>
                                        </div>
                                        <div className="col-md-2 accounting-menu">
                                            <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ACCOUNTING</h6>
                                            <Link to="/accounting" style={{ color: '#212529' }}><p>Accounting</p></Link>
                                            <Link to="/payroll" style={{ color: '#212529' }}><p>Payroll</p></Link>
                                            <Link to="/fees" style={{ color: '#212529' }}><p>Fees Management Software</p></Link>
                                            <h6 style={{ fontWeight: 'bold', paddingTop: 20, marginBottom: 20 }}>STUDENTS</h6>
                                            <Link to="/student-profile" style={{ color: '#212529' }}><p>Student Profile</p></Link>
                                            <Link to="/certificates" style={{ color: '#212529' }}><p>Certificate</p></Link>
                                            <Link to="/mentoring" style={{ color: '#212529' }}><p>Mentoring</p></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="dropdown">
                            <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Success
                            </span>
                            <div className="dropdown-menu successMenu" aria-labelledby="dropdownMenuButton">
                                <Link to="/implementation" className="dropdown-item success-item" style={{ color: '#212529' }}><span>Implementation</span></Link>
                                <Link to="/customer-success" className="dropdown-item success-item" style={{ color: '#212529' }}><span>Customer Success</span></Link>
                                <Link to="/learning-support" className="dropdown-item success-item" style={{ color: '#212529' }}><span>Learning & Support</span></Link>
                                <Link to="/knowledge-base" className="dropdown-item success-item" style={{ color: '#212529' }}><span>Knowledge Base</span></Link>
                            </div>
                        </div>

                        <div className="dropdown">
                            <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Resources
                            </span>
                            <div className="dropdown-menu resourceMenu" aria-labelledby="dropdownMenuButton">
                                <Link to="/case-studies" className="dropdown-item about-item" style={{ color: '#212529' }}><span>Case Studies</span></Link>
                                <Link to="/business-cases" className="dropdown-item about-item" style={{ color: '#212529' }}><span>Business Cases</span></Link>
                                <Link to="/webinar-on-demand" className="dropdown-item about-item" style={{ color: '#212529' }}><span>Webinars </span></Link>
                            </div>
                        </div>

                        <div className="dropdown">
                            <span className="dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                About
                            </span>
                            <div className="dropdown-menu aboutMenu" aria-labelledby="dropdownMenuButton">
                                <Link to="/about-vmedulife" className="dropdown-item about-item" style={{ color: '#212529' }}><span>About Company</span></Link>
                                <Link to="/our-presence" className="dropdown-item about-item" style={{ color: '#212529' }}><span>Our Presence</span></Link>
                                <Link to="/certifications" className="dropdown-item about-item" style={{ color: '#212529' }}><span>Certifications</span></Link>
                                <Link to="/events" className="dropdown-item about-item" style={{ color: '#212529' }}><span> Trainings & FDP's</span></Link>
                            </div>
                        </div>

                        <div>
                            <span id="dropdownMenuButton" style={{ cursor: 'pointer' }}>
                            <a href="https://www.vmedulife.com/vmlogin.php" target="_blank" style={{ color: '#212529' }}>Login</a>
                            </span>
                        </div>
                    </div>
                </div>
                {
                    this.state.showNavigationMenu &&
                        <div className="navigationMenu" id="myNavbar">
                            <div className="nav-dropdown mega-menu-full">
                                <span onClick={() => this.setState({ 
                                    showProducts: !this.state.showProducts,
                                    showSuccess: false,
                                    showResources: false,
                                    showAbout: false, })}
                                >
                                    Products
                                </span>
                                {
                                    this.state.showProducts &&
                                        <div className="productMenu"> 
                                            <div className="mega-menu-content">
                                                <div className="row link-container">
                                                    <div className="featuresData col-md-4">
                                                        <div className="outcome-education">
                                                            <img src={`${outcomeEducation}`} style={{ marginRight: 40 }} />
                                                            <Link to="/outcome-education" style={{ color: '#212529' }}><h6 style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Outcome Based Education Software (Accreditation)</h6></Link>
                                                        </div>
                                                        <hr />
                                                        <div className="academic-software">
                                                            <img src={`${graduation}`} style={{ marginRight: 40 }} />
                                                            <Link to="/academic-management" style={{ color: '#212529' }}><h6 style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Academic Management Software</h6></Link>
                                                        </div>
                                                        <hr />
                                                        <div className="office-automation">
                                                            <img src={`${settings}`} style={{ marginRight: 40 }} />
                                                            <Link to="/office-automation" style={{ color: '#212529' }}><h6 style={{ fontWeight: 'bold', cursor: 'pointer' }} onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Office Automation (ERP)</h6></Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-3 academic-menu">
                                                        <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ACADEMICS & EXAMINATION</h6>
                                                        <Link to="/academic-planning" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Academic Planning</p></Link>
                                                        <Link to="/outcome" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Outcome Software</p></Link>
                                                        <Link to="/online-exam" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Online Exam Software</p></Link>
                                                        <Link to="/assignment-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Online Assignment Software</p></Link>
                                                        <Link to="/result-analysis" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Result Analysis</p></Link>
                                                        <Link to="/rubrics" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Rubrics</p></Link>
                                                        <Link to="/iqac-reports" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>IQAC Reports</p></Link>
                                                        <Link to="/autonomous-exam-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Autonomy Exam Management</p></Link>
                                                        <Link to="/learning-management-system" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Learning Management System</p></Link>
                                                        <Link to="/course-file" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Course File - Academic</p></Link>
                                                        <Link to="/faculty-profile" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Faculty Profile</p></Link>
                                                        <Link to="/library-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Integrated Library Management System</p></Link>
                                                        <Link to="/training-placement" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Training & Placement Management</p></Link>
                                                        <Link to="/alumni-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Alumni Management</p></Link>
                                                    </div>
                                                    <div className="col-md-3 admin-menu">
                                                        <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ADMINISTRATIVE</h6>
                                                        <Link to="/employee-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Employee Management</p></Link>
                                                        <Link to="/admission-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Admission</p></Link>
                                                        <Link to="/online-feedback" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Online Feedback</p></Link>
                                                        <Link to="/committee-meetings-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Committee & Meeting Management</p></Link>
                                                        <Link to="/online-grievance" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Online Grievance Redressal Portal</p></Link>
                                                        <Link to="/forms" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Forms</p></Link>
                                                        <Link to="/leave-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Attendance & Leave Management System</p></Link>
                                                        <Link to="/event-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Events</p></Link>
                                                        <Link to="/noticeboard" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Notice Board</p></Link>
                                                        <Link to="/transport" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Transport Management System </p></Link>
                                                        <Link to="/sms-panel" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>SMS Panel </p></Link>
                                                        <Link to="/inward-outward" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Inward / Outward </p></Link>
                                                        <Link to="/hostel-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Hostel Management </p></Link>
                                                        <Link to="/inventory-management" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Inventory Management</p></Link>
                                                    </div>
                                                    <div className="col-md-2 accounting-menu">
                                                        <h6 style={{ fontWeight: 'bold', marginBottom: 20 }}>ACCOUNTING</h6>
                                                        <Link to="/accounting" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Accounting</p></Link>
                                                        <Link to="/payroll" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Payroll</p></Link>
                                                        <Link to="/fees" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Fees Management Software</p></Link>
                                                        <h6 style={{ fontWeight: 'bold', paddingTop: 20, marginBottom: 20 }}>STUDENTS</h6>
                                                        <Link to="/student-profile" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Student Profile</p></Link>
                                                        <Link to="/certificates" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Certificate</p></Link>
                                                        <Link to="/mentoring" style={{ color: '#212529' }}><p onClick={() => this.setState({ showNavigationMenu: false, showProducts: !this.state.showProducts, showSuccess: false, showResources: false, showAbout: false, })}>Mentoring</p></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>

                            <div className="dropdown nav-dropdown">
                                <span onClick={() => this.setState({ 
                                    showProducts: false,
                                    showSuccess: !this.state.showSuccess,
                                    showResources: false,
                                    showAbout: false, })}
                                >
                                    Success
                                </span>
                                {
                                    this.state.showSuccess &&
                                        <div className="successMenu">
                                            <Link to="/implementation" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: !this.state.showSuccess, showResources: false, showAbout: false, })}>Implementation</span></Link>
                                            <Link to="/customer-success" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: !this.state.showSuccess, showResources: false, showAbout: false, })}>Customer Success</span></Link>
                                            <Link to="/learning-support" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: !this.state.showSuccess, showResources: false, showAbout: false, })}>Learning & Support</span></Link>
                                            <Link to="/knowledge-base" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: !this.state.showSuccess, showResources: false, showAbout: false, })}>Knowledge Base</span></Link>
                                        </div>
                                }
                            </div>

                            <div className="dropdown nav-dropdown">
                                <span onClick={() => this.setState({ 
                                    showProducts: false,
                                    showSuccess: false,
                                    showResources: !this.state.showResources,
                                    showAbout: false, })}
                                >
                                    Resources
                                </span>
                                {
                                    this.state.showResources &&
                                        <div className="resourceMenu" aria-labelledby="dropdownMenuButton">
                                            <Link to="/case-studies" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: !this.state.showResources, showAbout: false, })}>Case Studies</span></Link>
                                            <Link to="/business-cases" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: !this.state.showResources, showAbout: false, })}>Business Cases</span></Link>
                                            <Link to="/webinar-on-demand" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: !this.state.showResources, showAbout: false, })}>Webinar</span></Link>
                                        </div>

                                }
                            </div>

                            <div className="dropdown nav-dropdown">
                                <span onClick={() => this.setState({ 
                                    showProducts: false,
                                    showSuccess: false,
                                    showResources: false,
                                    showAbout: !this.state.showAbout, })}
                            >
                                    About
                                </span>
                                {
                                    this.state.showAbout &&
                                        <div className="aboutMenu" aria-labelledby="dropdownMenuButton">
                                            <Link to="/about-vmedulife" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: false, showAbout: !this.state.showAbout, })}>About Company</span></Link>
                                            <Link to="/our-presence" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: false, showAbout: !this.state.showAbout, })}>Our Presence</span></Link>
                                            <Link to="/certifications" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: false, showAbout: !this.state.showAbout, })}>Certifications</span></Link>
                                            <Link to="/events" className="dropdown-item about-item" style={{ color: '#212529' }}><span onClick={() => this.setState({ showNavigationMenu: false, showProducts: false, showSuccess: false, showResources: false, showAbout: !this.state.showAbout, })}>Trainings & FDP's</span></Link>
                
                                        </div>
                                }
                            </div>

                            <div className="nav-dropdown">
                                <span id="dropdownMenuButton" style={{ cursor: 'pointer' }}>
                                <a href="https://www.vmedulife.com/vmlogin.php" target="_blank" style={{ color: '#212529' }}>Login</a>
                                </span>
                            </div>
                        </div>
                }
                {/**ENQUIRY BUTTON */}
                <div className="enquiryButtonDiv">
                    <Link to="/contact-us">
                        <button className="enquiry-button" style={{backgroundColor:'#e26c27' ,width:'200px'}}> <FontAwesomeIcon icon={['far', 'envelope']} size="lg" style={{marginRight: 5}} /> SEND ENQUIRY</button>
                    </Link>
                </div>
            </>
        );
    }
}

export default WebsiteHeader;