import React from 'react';
import line1 from 'WEBSITE/Components/Implementation/images/line1.png';
import line2 from 'WEBSITE/Components/Implementation/images/line2.png';
import painAreas from 'WEBSITE/Components/Implementation/images/painAreas.png';
import trainingPlan from 'WEBSITE/Components/Implementation/images/trainingPlan.png';
import successfulTraining from 'WEBSITE/Components/Implementation/images/successfulTraining.png';
import singleContact from 'WEBSITE/Components/Implementation/images/singleContact.png';
import helpDesk from 'WEBSITE/Components/Implementation/images/helpDesk.png';
import 'WEBSITE/Components/Implementation/implementation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Implementation extends React.Component {

    componentDidMount() {
        //Get the button
        let scrollButton = document.getElementById("scrollButton");

        // When the user scrolls down 20px from the top of the document, show the button
        window.onscroll = function() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                scrollButton.style.display = "block";
            } else {
                scrollButton.style.display = "none";
            }
        }

        this.scrollToTop();
    }

    // FUNCTION TO SCROLL TO TOP WHEN USER CLICK ON SCROLL TOP BUTTON
    scrollToTop = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    render() {
        return (
            <>
                <div className="scrollTop-button">
                    <button className="btn" id="scrollButton" title="Click to go to top" onClick={() => this.scrollToTop()}>
                        <FontAwesomeIcon icon={['fas','arrow-up']} size="lg" />
                    </button>
                </div>
                <div className="implementations-header">
                    <h5>Implementation</h5>
                </div>
                <div className="customer-message">
                    <h1>“Customer success is our passion”</h1>
                </div>
                <div className="customer-text">
                    <h6>vmedulife team will work with you consistently for implementation support to meet your unique requirements and achieve your organization goals.</h6>
                </div>
                <div className="implementations-container">
                    <div className="pain-areas">
                        <div className="painAreas-image col-md-6" style={{ paddingLeft: 0 }}>
                            <img src={`${painAreas}`} className="painAreas-img" />
                        </div>
                        <div className="pain-areas-text col-md-6" style={{ paddingRight: 0 }}>
                            <h5>Identifying the pain areas in organization</h5>
                            <h6>We believe in finding out the root cause and working on it to make it simple, quick and easy.</h6>
                            <img src={`${line1}`} className="line1-img" />
                        </div>
                    </div>
                    <div className="training-plan">
                        <div className="training-plan-text col-md-6" style={{ paddingRight: 0 }}>
                            <h5>Design training plan</h5>
                            <h6>After finding root cause, we make industry leading plan for your organization to achieve prominent mark in the market.</h6>
                            <img src={`${line2}`} className="line2-img" />
                        </div>
                        <div className="trainingPlan-image col-md-6" style={{ paddingLeft: 0 }}>
                            <img src={`${trainingPlan}`} className="trainingPlan-img" />
                        </div>
                    </div>
                    <div className="successful-training">
                        <div className="successfulTraining-image col-md-6" style={{ paddingLeft: 0 }}>
                            <img src={`${successfulTraining}`} className="successfulTraining-img" />
                        </div>
                        <div className="successful-training-text col-md-6" style={{ paddingRight: 0 }}>
                            <h5>Successful training</h5>
                            <h6>We conduct training at your organization with “industry leading plan” and make it more convenient for your use and purpose.</h6>
                            <img src={`${line1}`} className="line1-img" />
                        </div>
                    </div>
                    <div className="single-contact">
                        <div className="single-contact-text col-md-6" style={{ paddingRight: 0 }}>
                            <h5>Single point of contact</h5>
                            <h6>For your queries and issues we have your back with leading problem solvers team which help you thoroughly and solve your queries or issues within stipulated period of time.</h6>
                            <img src={`${line2}`} className="line2-img" />
                        </div>
                        <div className="singleContact-image col-md-6" style={{ paddingLeft: 0 }}>
                            <img src={`${singleContact}`} className="singleContact-img" />
                        </div>
                    </div>
                    <div className="help-desk">
                        <div className="helpDesk-image col-md-6" style={{ paddingLeft: 0 }}>
                            <img src={`${helpDesk}`} className="helpDesk-img" />
                        </div>
                        <div className="help-desk-text col-md-6" style={{ paddingRight: 0 }}>
                            <h5>Help desk and custom enhancement</h5>
                            <h6>We at “vmedulife” are always happy to listen to new ideas and techniques for enhancing our services in need of demand and nurture our quality for everyone.</h6>
                        </div>
                    </div>
                </div>
                <div className="contactDetails">
                    <h5>Contact us to learn more about our approach to customer success</h5>
                    <button className="btn contactButton">CONTACT US</button>
                </div>
            </>
        );
    }
}

export default Implementation;